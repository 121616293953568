import { css } from "@emotion/react";
import { mediaQuery, spacing, lineHeights, fontSizes } from "../../../../core/styles";

export const styles = {
  divPoint: () =>
    css({
      width: "100%",
      textAlign: "center",
      padding: `0`,
      ":last-of-type": {
        marginBottom: "0",
      },
      [mediaQuery.largerThanMobile]: {
        alignItems: "initial",
        padding: `0 ${spacing.baseUnitMobile3}`,
      },
    }),

  icon: () =>
    css({
      width: "72px",
      height: "72px",
    }),
  title: () =>
    css({
      margin: spacing.baseUnitMobile1,
      fontWeight: 500,
      [mediaQuery.desktopOrLarger]: {
        fontSize: fontSizes.heading.H4.mobile,
        lineHeight: lineHeights.heading.H4.mobile,
      },
    }),
};
